var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticStyle:{"padding":"1rem"}},[_c('b-col',{attrs:{"lg":"9"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"space-between"}},[_c('h5',{staticStyle:{"margin-top":"0.5rem"}},[_vm._v(" "+_vm._s(_vm.item.titulo.toUpperCase())+" ")]),(_vm.item.tooltip)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"bx bx-help-circle",staticStyle:{"font-size":"1.2rem"},attrs:{"title":_vm.item.tooltip}}):_vm._e()]),_c('p',{staticStyle:{"font-size":"12.5px","margin-top":"1rem"}},[_vm._v(" "+_vm._s(_vm.item.descricao)+" ")]),([5, 6].includes(_vm.item.tipo_tabela))?_c('StepItemTable',{attrs:{"item":_vm.item,"diagnosis":_vm.diagnosis,"diagnosisProduct":_vm.diagnosisProduct}}):_vm._e(),(
        [2, 5, 6].includes(_vm.diagnosisProduct.produto_diagnostico_id) ||
        ['Seguro patrimonial'].includes(_vm.item.grupo) ||
        (_vm.item.id_campo === 14 &&
          _vm.diagnosisProduct.produto_diagnostico_id !== 3)
      )?_c('ContactDataTable',{attrs:{"diagnosis":_vm.diagnosis,"editMode":true}}):_vm._e(),(_vm.item.grupo === 'Saúde')?_c('ContactDataHealthTable',{attrs:{"diagnosis":_vm.diagnosis,"editMode":true}}):_vm._e(),([2, 5, 6].includes(_vm.diagnosisProduct.produto_diagnostico_id))?_c('ConsultantDataTable',{attrs:{"item":_vm.item,"editMode":true}}):_vm._e()],1),_c('b-col',{staticClass:"px-xl-5 d-flex flex-column justify-content-center",attrs:{"lg":"3"}},[(_vm.item.id_campo !== 14)?_c('div',{staticClass:"mb-xl-5"},[_c('p',[_vm._v("Fazer cotação?")]),_c('b-form-group',{staticClass:"mb-3"},[_c('b-form-radio',{staticClass:"mb-3",attrs:{"value":true},on:{"change":function () { return _vm.handleTodoQuotation(_vm.item, true); }},model:{value:(_vm.item.fazer_cotacao),callback:function ($$v) {_vm.$set(_vm.item, "fazer_cotacao", $$v)},expression:"item.fazer_cotacao"}},[_vm._v(" Sim ")]),_c('b-form-radio',{attrs:{"value":false},on:{"change":function () { return _vm.handleTodoQuotation(_vm.item, false); }},model:{value:(_vm.item.fazer_cotacao),callback:function ($$v) {_vm.$set(_vm.item, "fazer_cotacao", $$v)},expression:"item.fazer_cotacao"}},[_vm._v(" Não ")])],1)],1):_vm._e()]),(
      ![0, 5, 6, 7].includes(_vm.item.tipo_tabela) ||
      _vm.diagnosisProduct.produto_diagnostico_id === 5
    )?_c('StepItemTable',{attrs:{"item":_vm.item,"diagnosis":_vm.diagnosis,"diagnosisProduct":_vm.diagnosisProduct}}):_vm._e(),_c('b-col',{staticClass:"mt-4 d-flex justify-content-end",attrs:{"cols":"12"}},[_c('div',[_c('ButtonEditComment',{attrs:{"icon":"plus","title":((_vm.item.diagnostico_comentario &&
          _vm.item.diagnostico_comentario.comentario
            ? 'EDITAR'
            : 'ADICIONAR') + " COMENTÁRIO"),"openModalEditComment":function () { return _vm.openModalAddEditComment(); }}}),(
          _vm.item.diagnostico_comentario_especialista &&
          _vm.item.diagnostico_comentario_especialista.comentario
        )?_c('b-button-group',{staticClass:"mt-3",staticStyle:{"padding":"6px 0"},on:{"click":function () { return _vm.viewSpecialistComment(); }}},[_c('b-button',{staticStyle:{"background":"var(--border-blue-light)","border-color":"var(--border-blue-light)","height":"3rem","width":"3rem"}},[_c('i',{staticClass:"fas fa-eye",staticStyle:{"font-size":"1rem"}})]),_c('b-button',{staticStyle:{"height":"3rem","font-size":"10px"},attrs:{"variant":"primary"}},[_vm._v(" Ver comentário do especialista ")])],1):_vm._e()],1)]),_c('ModalViewComment',{attrs:{"title":"Comentário do especialista","isOpen":_vm.isOpenModalViewComment,"diagnosisComment":_vm.item.diagnostico_comentario_especialista},on:{"closeModal":function($event){return _vm.closeModalViewComment()}}}),_c('ModalAddEditComment',{attrs:{"isOpen":_vm.isOpenModalAddEditComment,"data":_vm.modalData},on:{"onSaveComment":function (diagnosisComment) { return _vm.onSaveComment(diagnosisComment); },"closeModal":function($event){return _vm.closeModalAddEditComment()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }