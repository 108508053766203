<template>
  <b-row style="padding: 1rem">
    <b-col lg="9">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <h5 style="margin-top: 0.5rem">
          {{ item.titulo.toUpperCase() }}
        </h5>
        <i
          v-if="item.tooltip"
          v-b-tooltip.hover
          :title="item.tooltip"
          class="bx bx-help-circle"
          style="font-size: 1.2rem"
        ></i>
      </div>

      <p style="font-size: 12.5px; margin-top: 1rem">
        {{ item.descricao }}
      </p>

      <StepItemTable
        :item="item"
        :diagnosis="diagnosis"
        :diagnosisProduct="diagnosisProduct"
        v-if="[5, 6].includes(item.tipo_tabela)"
      />

      <ContactDataTable
        :diagnosis="diagnosis"
        :editMode="true"
        v-if="
          [2, 5, 6].includes(diagnosisProduct.produto_diagnostico_id) ||
          ['Seguro patrimonial'].includes(item.grupo) ||
          (item.id_campo === 14 &&
            diagnosisProduct.produto_diagnostico_id !== 3)
        "
      />

      <ContactDataHealthTable
        :diagnosis="diagnosis"
        :editMode="true"
        v-if="item.grupo === 'Saúde'"
      />

      <ConsultantDataTable
        :item="item"
        :editMode="true"
        v-if="[2, 5, 6].includes(diagnosisProduct.produto_diagnostico_id)"
      />
    </b-col>

    <b-col lg="3" class="px-xl-5 d-flex flex-column justify-content-center">
      <div class="mb-xl-5" v-if="item.id_campo !== 14">
        <p>Fazer cotação?</p>
        <b-form-group class="mb-3">
          <b-form-radio
            class="mb-3"
            v-model="item.fazer_cotacao"
            :value="true"
            @change="() => handleTodoQuotation(item, true)"
          >
            Sim
          </b-form-radio>

          <b-form-radio
            v-model="item.fazer_cotacao"
            :value="false"
            @change="() => handleTodoQuotation(item, false)"
          >
            Não
          </b-form-radio>
        </b-form-group>
      </div>
    </b-col>

    <StepItemTable
      :item="item"
      :diagnosis="diagnosis"
      :diagnosisProduct="diagnosisProduct"
      v-if="
        ![0, 5, 6, 7].includes(item.tipo_tabela) ||
        diagnosisProduct.produto_diagnostico_id === 5
      "
    />

    <b-col cols="12" class="mt-4 d-flex justify-content-end">
      <div>
        <ButtonEditComment
          icon="plus"
          :title="`${
            item.diagnostico_comentario &&
            item.diagnostico_comentario.comentario
              ? 'EDITAR'
              : 'ADICIONAR'
          } COMENTÁRIO`"
          :openModalEditComment="() => openModalAddEditComment()"
        />

        <b-button-group
          class="mt-3"
          style="padding: 6px 0"
          @click="() => viewSpecialistComment()"
          v-if="
            item.diagnostico_comentario_especialista &&
            item.diagnostico_comentario_especialista.comentario
          "
        >
          <b-button
            style="
              background: var(--border-blue-light);
              border-color: var(--border-blue-light);
              height: 3rem;
              width: 3rem;
            "
          >
            <i class="fas fa-eye" style="font-size: 1rem"></i>
          </b-button>

          <b-button style="height: 3rem; font-size: 10px" variant="primary">
            Ver comentário do especialista
          </b-button>
        </b-button-group>
      </div>
    </b-col>

    <ModalViewComment
      title="Comentário do especialista"
      :isOpen="isOpenModalViewComment"
      :diagnosisComment="item.diagnostico_comentario_especialista"
      @closeModal="closeModalViewComment()"
    />

    <ModalAddEditComment
      :isOpen="isOpenModalAddEditComment"
      :data="modalData"
      @onSaveComment="(diagnosisComment) => onSaveComment(diagnosisComment)"
      @closeModal="closeModalAddEditComment()"
    />
  </b-row>
</template>

<script>
import {
  updateDiagnosisProductItem,
  updateDiagnosisProductItemOpcoes,
  uploadProductItemOption
} from '@/services/requests/diagnosis';
import { cloneObject } from '@/helpers';
import ContactDataTable from '@/components/common/ContactDataTable';
import ContactDataHealthTable from '@/components/common/ContactDataHealthTable';
import ConsultantDataTable from '@/components/common/ConsultantDataTable';

import { statusStudy } from '@/constants/options';
import ButtonEditComment from '@/components/Buttons/ButtonEditComment';
import StepItemTable from './StepItemTable';

import ModalAddEditComment from '@/components/common/Modals/ModalAddEditComment';
import ModalViewComment from '@/components/common/Modals/ModalViewComment';

export default {
  name: 'StepItem',
  props: {
    diagnosis: Object,
    item: Object,
    color: String,
    diagnosisProduct: Object
  },
  data() {
    return {
      statusStudy,
      currentOptionIndex: 0,
      editMode: false,
      modalData: null,
      isOpenModalAddEditComment: false,
      isOpenModalViewComment: false
    };
  },
  components: {
    ButtonEditComment,
    StepItemTable,
    ContactDataTable,
    ContactDataHealthTable,
    ConsultantDataTable,
    ModalAddEditComment,
    ModalViewComment
  },
  methods: {
    handleTodoQuotation(item, value) {
      const data = cloneObject(item);
      data.fazer_cotacao = value;

      let acao = data.fazer_cotacao ? 'marcar' : 'desmarcar';

      updateDiagnosisProductItem(item.diagnostico_produto_item_id, data)
        .then((res) => {
          const item = res.data.result.data;
          this.$set(this.item, 'fazer_cotacao', item.fazer_cotacao);
        })
        .catch((error) => {
          this.$set(this.item, 'fazer_cotacao', !value);

          let errorMessage = `Houve um erro ao ${acao} este item para fazer cotação`;

          if (error.response && error.response.data.statusCode !== 200) {
            errorMessage = error.response.data.statusMessage;
          }

          alert(errorMessage);
        });
    },

    series(necessidades, valor_atual, valor_necessario, sugeridos) {
      return [
        {
          name: 'Necessidades',
          data: [necessidades],
          color: 'var(--color-graph-1)',
          type: 'column'
        },
        {
          name: 'Valor atual',
          data: [valor_atual],
          color: 'var(--color-graph-2)',
          type: 'column'
        },
        {
          name: 'Valor necessário',
          data: [valor_necessario],
          color: 'var(--color-graph-3)',
          type: 'column'
        },
        {
          name: 'Valor Sugerido',
          data: [sugeridos],
          color: 'var(--color-graph-4)',
          type: 'column'
        }
      ];
    },

    async handleFileUpload(option, optionIndex) {
      if (!option.file) return;

      if (!option.diagnostico_produto_item_opcao_id) {
        const updatedItem = await this.saveQuotationRequest();
        const updatedOption =
          updatedItem.diagnostico_produto_item_opcoes[optionIndex];

        option.diagnostico_produto_item_opcao_id =
          updatedOption.diagnostico_produto_item_opcao_id;
      }

      const formData = new FormData();
      formData.append('file', option.file);

      const optionId = option.diagnostico_produto_item_opcao_id;

      uploadProductItemOption(optionId, option.file)
        .then((res) => {
          const optionIndex = this.item.diagnostico_produto_item_opcoes.findIndex(
            (option) => optionId === option.diagnostico_produto_item_opcao_id
          );

          const updatedOption = {
            ...cloneObject(option),
            ...res.data.result.data
          };

          this.$set(
            this.item.diagnostico_produto_item_opcoes,
            optionIndex,
            updatedOption
          );
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    handleAddOption() {
      const option = {
        diagnostico_produto_item_opcao_id: null,
        seguradora: null,
        capital_segurado: 0,
        solucao: null,
        periodo_cobertura: null,
        periodo_pagamento: null,
        premio_mensal: 0,
        premio_anual: 0
      };

      this.item.diagnostico_produto_item_opcoes.push(option);
      this.currentOptionIndex =
        this.item.diagnostico_produto_item_opcoes.length - 1;
    },

    handleDeleteOption() {
      const options = cloneObject(this.item.diagnostico_produto_item_opcoes);
      const index = this.currentOptionIndex;

      options.splice(index, 1);

      this.$set(this.item, 'diagnostico_produto_item_opcoes', options);

      if (this.item.diagnostico_produto_item_opcoes[index - 1]) {
        this.currentOptionIndex = index - 1;
      } else if (this.item.diagnostico_produto_item_opcoes[index]) {
        this.currentOptionIndex = index;
      } else {
        this.currentOptionIndex = null;
      }
    },

    handleCancel(item) {
      this.editMode = false;
      item.diagnostico_produto_item_opcoes = [];
    },

    saveQuotationRequest() {
      return new Promise((resolve, reject) => {
        const data = {
          diagnostico_produto_item_opcoes: cloneObject(
            this.item.diagnostico_produto_item_opcoes
          )
        };

        updateDiagnosisProductItemOpcoes(
          this.item.diagnostico_produto_item_id,
          data
        )
          .then((res) => {
            this.item.aguardando_resposta = true;
            this.$set(
              this.item,
              'diagnostico_produto_item_opcoes',
              res.data.result.data.diagnostico_produto_item_opcoes
            );
            this.editMode = false;

            resolve(res.data.result.data);
          })
          .catch((error) => {
            let errorMessage = 'Houve um erro ao salvar as opções';

            if (error.response) {
              if (
                error.response.data.statusCode &&
                error.response.data.statusCode !== 200
              ) {
                errorMessage = error.response.data.statusMessage;
              }

              if (
                error.response.data.message &&
                !error.response.data.message.diagnostico_produto_item_opcoes
              ) {
                errorMessage = 'Todos os valores são obrigatórios';
              }
            }

            alert(errorMessage);
            reject(error);
          });
      });
    },

    handleEditQuotation() {
      this.editMode = true;
      this.optionsBackup = cloneObject(
        this.item.diagnostico_produto_item_opcoes
      );
    },

    handleSaveQuotation() {
      this.saveQuotationRequest();
    },

    openModalAddEditComment() {
      this.modalData = this.item.diagnostico_comentario || {
        diagnostico_comentario_id: null,
        diagnostico_produto_item_id: this.item.diagnostico_produto_item_id,
        comentario: null,
        download_url: null
      };

      this.isOpenModalAddEditComment = true;
    },

    onSaveComment(diagnosisComment) {
      this.$set(this.item, 'diagnostico_comentario', diagnosisComment);
      this.closeModalAddEditComment();
    },

    closeModalAddEditComment() {
      this.isOpenModalAddEditComment = false;
    },

    viewSpecialistComment() {
      this.isOpenModalViewComment = true;
    },

    closeModalViewComment() {
      this.isOpenModalViewComment = false;
    }
  }
};
</script>
