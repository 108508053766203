<template>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box">
        <b-breadcrumb :items="items" class="m-0" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
};
</script>
