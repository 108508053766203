<template>
  <b-modal v-model="isOpenModal" ok-only>
    <template #modal-header>
      <div style="margin: 0 auto">
        <h5 style="color: var(--grey)">Observações do consultor</h5>
      </div>
    </template>

    <template #modal-footer="{ ok }">
      <b-button size="md" variant="success" @click="ok()">OK</b-button>
    </template>

    <div class="p-3" style="border-color: var(--light-grey) !important">
      <div v-for="observation in observations" :key="observation.title">
        <strong>{{ observation.title }}</strong>
        <p v-if="observation.content">{{ observation.content }}</p>
        <p v-else style="color: var(--gray)">Sem observação</p>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalConsultantObservations',
  props: {
    isOpen: Boolean,
    diagnosisComment: Object,
    person: Object
  },
  methods: {
    ok() {
      this.cancel();
    },
    cancel() {
      this.$emit('closeModal');
    }
  },
  computed: {
    isOpenModal: {
      get() {
        return this.isOpen;
      },
      set(value) {
        if (!value) {
          this.cancel();
        }
      }
    },
    observations() {
      if (!this.person) return [];

      const observations = [
        {
          title: 'Perfil do Cliente',
          content: this.person.pessoa_observacao
        },
        {
          title: 'Renda',
          content: this.person.pessoa_renda.pessoa_renda_observacao
        },
        {
          title: 'Patrimônio e Investimentos',
          content: this.person.patrimonio_investimento.observacao
        },
        {
          title: 'Despesas',
          content: this.person.pessoa_despesa.observacao
        },
        {
          title: 'Planos e Sonhos',
          content: this.person.pessoa_plano.plano_observacao
        },
        {
          title: 'Liberdade Financeira',
          content: this.person.pessoa_previdencia_privada.observacao
        },
        {
          title: 'Riscos de Gastos com Saúde',
          content: this.person.pessoa_saude.observacao
        },
        {
          title: 'Riscos com Perda de Renda',
          content: this.person.pessoa_plano.planob_observacao
        },
        {
          title: 'Prioridades',
          content: this.person.observacao_prioridades
        }
      ];

      return observations;
    }
  }
};
</script>
