<template>
  <div class="container">
    <div>
      <IconLife
        v-if="icon === 'fas fa-heartbeat icon-heart'"
        :color="currentTab !== index && color"
      />

      <IconHealth
        v-if="icon === 'fas fa-medkit icon-health'"
        :color="currentTab !== index && color"
      />

      <IconDream
        v-if="icon === 'bx bx-cloud icon-cloud'"
        :color="currentTab !== index && color"
      />

      <IconMoney
        v-if="icon === 'bx bx-credit-card icon-cloud'"
        :color="currentTab !== index && color"
      />

      <IconProtection
        v-if="icon === 'bx bx-check-shield icon-cloud'"
        :color="currentTab !== index && color"
      />

      <IconExchange
        v-if="icon === 'bx bx-dollar icon-cloud'"
        :color="currentTab !== index && color"
      />

      <IconRealState
        v-if="icon === 'bx bx-home icon-cloud'"
        :color="currentTab !== index && color"
      />
    </div>

    <strong
      class="productName"
      :style="{ color: currentTab !== index ? color : '' }"
    >
      {{ name }}
    </strong>

    <div class="check-icon-container">
      <i
        v-if="itens.filter((item) => item.fazer_cotacao).length > 0"
        class="bx bxs-check-circle check-icon"
      ></i>
    </div>
  </div>
</template>

<script>
import IconLife from '@/assets/images/icons/products/IconLife';
import IconHealth from '@/assets/images/icons/products/IconHealth';
import IconDream from '@/assets/images/icons/products/IconDream';
import IconProtection from '@/assets/images/icons/products/IconProtection';
import IconMoney from '@/assets/images/icons/products/IconMoney';
import IconExchange from '@/assets/images/icons/products/IconExchange';
import IconRealState from '@/assets/images/icons/products/IconRealState';

export default {
  props: {
    icon: String,
    currentTab: Number,
    index: Number,
    color: String,
    name: String,
    itens: Array
  },
  components: {
    IconLife,
    IconHealth,
    IconDream,
    IconProtection,
    IconMoney,
    IconExchange,
    IconRealState
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  position: relative;
}

.productName {
  margin-left: 0.2rem;
}

.product-icon {
  font-size: 1.3rem;
  color: #e9e9f2;
}

strong {
  text-align: center;
  font-size: 12px;
  color: #e9e9f2;
}

.check-icon-container {
  position: absolute;
  width: 5px;
  align-self: flex-start;

  top: -13px;
  right: -13px;
}

.check-icon {
  position: absolute;
  top: 1%;
  right: 1%;
  font-size: 1.3rem;
  color: #e9e9f2;
}

.nav-pills .tab-product .nav-link:not(.active) {
  background-color: #e9e9f2 !important;

  .check-icon {
    color: #29b059;
  }
}
</style>
