<template>
  <main id="diagnosis-list">
    <ModalEditComment @onSuccess="loadDiagnosis()" />
    <ModalViewCommentOld title="Comentário do especialista" />
    <ModalSuccess />
    <PageHeader :items="breadcrumb" />
    <ModalConsultantObservations
      :isOpen="isOpenModalConsultantObservations"
      @closeModal="closeModalConsultantObservations()"
      :person="diagnosis ? diagnosis.pessoa : null"
    />

    <b-row>
      <b-col>
        <b-card>
          <TitleAndDescriptionScreen
            :diagnosis="diagnosis"
            title="Direcionamento do consultor para o estudo de -"
            :customerName="diagnosis.pessoa.pessoa_nome"
            description="Antes de realizar as cotações, selecione as opções de produtos que mais se adequam às necessidades do seu cliente e preencha os campos abaixo."
            v-if="diagnosis"
          />

          <div>
            <b-button
              class="d-flex align-items-center ml-auto"
              @click="openModalConsultantObservations()"
            >
              <i class="bx bx-comment-detail mr-2" style="font-size: 1rem" />
              Observações do consultor
            </b-button>
          </div>

          <div style="width: 100%">
            <b-card no-body v-if="diagnosis">
              <b-tabs
                pills
                card
                content-class="w-100"
                nav-wrapper-class="bg-white"
                v-model="currentTab"
              >
                <b-tab
                  no-body
                  :title-link-attributes="{
                    style:
                      currentTab === index
                        ? `background-color: ${diagnosisProduct.produto_diagnostico.cor} !important`
                        : ''
                  }"
                  title-item-class="tab-product"
                  title-link-class="bg-light p-3 mr-1 d-flex align-items-center"
                  :key="diagnosisProduct.diagnostico_produto_id"
                  v-for="(
                    diagnosisProduct, index
                  ) in diagnosis.diagnostico_produtos"
                >
                  <template #title>
                    <TabProduct
                      :icon="diagnosisProduct.produto_diagnostico.icone"
                      :currentTab="currentTab"
                      :index="index"
                      :color="diagnosisProduct.produto_diagnostico.cor"
                      :name="diagnosisProduct.nome_produto"
                      :itens="diagnosisProduct.diagnostico_produto_itens"
                    />
                  </template>

                  <Step
                    :diagnosis="diagnosis"
                    :data="diagnosisProduct.diagnostico_produto_itens"
                    :diagnosisProduct="diagnosisProduct"
                  />
                </b-tab>
              </b-tabs>

              <div class="btn-result-exame">
                <Button
                  :onClick="() => generateExamResult()"
                  text="GERAR RESULTADO DO EXAME"
                />
              </div>
            </b-card>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </main>
</template>

<script>
import { updatePhase, requestDiagnosis } from '@/services/requests/diagnosis';

import PageHeader from '@/components/PageHeader';
import ModalViewCommentOld from '@/components/common/Modals/ModalViewCommentOld';
import Step from '@/components/DiagnosisComplete/ConsultantDirection/Step';
import ModalSuccess from '@/components/Modals/ModalSuccess';
import ModalEditComment from '@/components/Modals/ModalEditComment';
import TitleAndDescriptionScreen from '@/components/DiagnosisComplete/common/TitleAndDescriptionScreen';
import TabProduct from '@/components/DiagnosisComplete/common/TabProduct';
import Button from '@/components/DiagnosisComplete/common/Button';
import ModalConsultantObservations from '@/components/common/Modals/ModalConsultantObservations';

export default {
  name: 'DiagnosisPage',
  components: {
    PageHeader,
    ModalViewCommentOld,
    Step,
    ModalSuccess,
    ModalEditComment,
    TitleAndDescriptionScreen,
    TabProduct,
    Button,
    ModalConsultantObservations
  },
  data() {
    return {
      diagnosis: null,
      currentTab: 0,
      isOpenModalConsultantObservations: false
    };
  },
  methods: {
    generateExamResult() {
      if (!this.diagnosis) return;

      const dontContinueTheDiagnosis = !this.askToContinueDiagnosis();
      if (dontContinueTheDiagnosis) return;

      const diagnosisId = this.diagnosis.diagnostico_id;

      const data = {
        estagio_id: 2
      };

      if (!this.diagnosis.enviado_especialista) {
        data.enviado_especialista = confirm(
          'Deseja enviar para o especialista?'
        );
        this.diagnosis.enviado_especialista = data.enviado_especialista;
      }

      let routeName = this.diagnosis.enviado_especialista
        ? 'AwaitingSpecialist'
        : 'ExamResult';

      if (this.diagnosis.diagnostico_historico.estagio_id >= data.estagio_id) {
        this.$router.push({
          name: routeName,
          params: { diagnosisId }
        });
        return;
      }

      updatePhase(diagnosisId, data)
        .then((res) => {
          const diagnosisId = res.data.result.data.diagnostico_id;

          this.$router.push({
            name: routeName,
            params: { diagnosisId }
          });
        })
        .catch((error) => {
          let errorMessage = 'Houve um erro ao gerar o resultado do exame';

          if (error.response && error.response.data.statusCode !== 200) {
            errorMessage = error.response.data.statusMessage;
          }

          alert(errorMessage);
        });
    },

    askToContinueDiagnosis() {
      const uncompletedProducts = [];

      this.diagnosis.diagnostico_produtos.forEach((diagnosisProduct) => {
        const completed =
          diagnosisProduct.diagnostico_produto_itens.filter(
            (item) => item.fazer_cotacao
          ).length > 0;

        if (completed) return;

        uncompletedProducts.push(diagnosisProduct.nome_produto);
      });

      if (uncompletedProducts.length === 0) return true;

      return confirm(
        `Você não concluiu as análises das abas de: ${uncompletedProducts.join(
          ', '
        )}. Deseja seguir mesmo assim?`
      );
    },

    loadDiagnosis() {
      const { diagnosisId } = this.$route.params;

      requestDiagnosis(diagnosisId)
        .then((res) => {
          this.diagnosis = res.data.result.data;
        })
        .catch((error) => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
          this.$store.state.modal.errorMessageModal =
            error.response.data.message;
        });
    },

    openModalConsultantObservations() {
      this.isOpenModalConsultantObservations = true;
    },

    closeModalConsultantObservations() {
      this.isOpenModalConsultantObservations = false;
    }
  },
  computed: {
    breadcrumb() {
      const customerId = this.diagnosis
        ? this.diagnosis.pessoa.cliente.cliente_id
        : null;

      return [
        {
          text: 'Dashboards',
          href: '/'
        },
        {
          text: 'Gerar estudo completo',
          href: `/diagnosis/complete/${customerId}`
        },
        {
          text: 'Direcionamento do consultor',
          active: true
        }
      ];
    }
  },
  mounted() {
    this.loadDiagnosis();
  }
};
</script>

<style lang="scss" scoped>
.btn-result-exame {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
}
</style>
