<template>
  <td>
    <b-form-input v-model="option" v-if="columnTypeComputed === 0" />
    <money
      class="form-control"
      v-bind="money"
      v-model="tableItem[column]"
      v-if="columnTypeComputed === 1"
      @blur.native="handleChangeInput"
    />

    <b-form-input
      type="text"
      v-mask="'######'"
      v-model="tableItem[column]"
      v-if="columnTypeComputed === 2"
      @blur="handleChangeInput"
    />

    <b-form-select
      v-model="tableItem[column]"
      :options="[
        { value: '', text: 'Selecione...' },
        ...tableItem.opcoes_select
      ]"
      v-if="columnTypeComputed === 3"
      @change="handleChangeInput"
    />
  </td>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import { Money } from 'v-money';

import { updateProductItemTableItem } from '@/services/requests/diagnosis';

Vue.use(VueMask);

export default {
  name: 'StepItemTableInputCell',
  components: { Money },
  props: {
    tableItem: Object,
    column: String,
    columnType: Number
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      }
    };
  },
  methods: {
    handleChangeInput() {
      const tableItem = JSON.parse(JSON.stringify(this.tableItem));

      updateProductItemTableItem(
        tableItem.diagnostico_produto_item_tabela_item_id,
        tableItem
      )
        .then(() => {})
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    }
  },
  computed: {
    columnTypeComputed() {
      if (this.columnType !== undefined) return this.columnType;

      return this.tableItem.tipo_coluna;
    }
  }
};
</script>
