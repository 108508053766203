<template>
  <b-col cols="12" class="mt-3 table-column">
    <table class="table" v-if="item.tipo_tabela === 1">
      <tr
        class="bg-secondary text-white"
        :style="`
          border-radius: 5px;
        `"
      >
        <th>
          <div
            style="
              height: 2rem;
              width: 6rem;
              display: flex;
              align-items: center;
            "
          >
            ITENS
          </div>
        </th>
        <th></th>
        <th v-if="[2, 3].includes(item.id_campo)">
          <div
            style="
              height: 2rem;
              width: 6rem;
              display: flex;
              align-items: center;
              font-size: 11.5px;
            "
          >
            DESPESAS COM O PRAZO (EM ANOS)
          </div>
        </th>
        <th>
          <div
            style="
              height: 2rem;
              width: 7rem;
              display: flex;
              align-items: center;
              font-size: 11.5px;
            "
          >
            NECESSIDADE
          </div>
        </th>
        <th>
          <div
            style="
              height: 2rem;
              width: 7rem;
              display: flex;
              align-items: center;
              font-size: 11.5px;
            "
          >
            VALOR ATUAL
          </div>
        </th>
        <th>
          <div
            style="
              height: 2rem;
              width: 7rem;
              display: flex;
              align-items: center;
              font-size: 11.5px;
            "
          >
            VALOR NECESSÁRIO
          </div>
        </th>
        <th>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span style="font-size: 11.5px">VALOR SUGERIDO</span>

            <b-badge variant="dark-primary py-2 px-3" style="font-size: 11.5px">
              ESTIMATIVA
            </b-badge>
          </div>
        </th>
      </tr>

      <tr
        v-for="tableItem in item.diagnostico_produto_item_tabela_itens"
        :key="`table-item-${tableItem.diagnostico_produto_item_tabela_item_id}`"
      >
        <td>{{ tableItem.itens }}</td>
        <td>
          <i
            v-b-tooltip.hover
            :title="tableItem.tooltip"
            class="bx bx-help-circle"
            style="font-size: 1.2rem"
            v-if="tableItem.tooltip"
          />
        </td>
        <td v-if="[2, 3].includes(item.id_campo)">
          <StepItemTableInputCell
            column="despesa_prazo"
            :columnType="2"
            :tableItem="tableItem"
          />
        </td>
        <td>
          <span v-if="tableItem.necessidades">
            R$ {{ tableItem.necessidades | decimalWithoutCents }}
          </span>
          <span v-if="!tableItem.necessidades"> - </span>
        </td>
        <td>
          <span v-if="tableItem.valor_atual">
            R$ {{ tableItem.valor_atual | decimalWithoutCents }}
          </span>
          <span v-if="!tableItem.valor_atual"> - </span>
        </td>
        <td>
          <span v-if="tableItem.valor_necessario">
            R$ {{ tableItem.valor_necessario | decimalWithoutCents }}
          </span>
          <span v-if="!tableItem.valor_necessario"> - </span>
        </td>
        <StepItemTableInputCell :tableItem="tableItem" column="sugeridos" />
      </tr>

      <tr v-if="item.diagnostico_produto_item_tabela_itens.length > 1">
        <td><strong>Total</strong></td>
        <td></td>
        <td v-if="[2, 3].includes(item.id_campo)">
          {{ item.despesa_prazo }} anos
        </td>
        <td>
          <strong v-if="item.necessidades">
            R$ {{ item.necessidades | decimalWithoutCents }}
          </strong>
          <strong v-if="!item.necessidades"> - </strong>
        </td>
        <td>
          <strong v-if="item.valor_atual">
            R$ {{ item.valor_atual | decimalWithoutCents }}
          </strong>
          <strong v-if="!item.valor_atual"> - </strong>
        </td>
        <td>
          <strong v-if="item.valor_necessario">
            R$ {{ item.valor_necessario | decimalWithoutCents }}
          </strong>
          <strong v-if="!item.valor_necessario"> - </strong>
        </td>
        <td>
          <strong v-if="totalSugeridos">
            R$ {{ totalSugeridos | decimalWithoutCents }}
          </strong>
          <strong v-if="!totalSugeridos"> - </strong>
        </td>
      </tr>
    </table>

    <table class="table" v-if="item.tipo_tabela === 2">
      <tr
        class="bg-secondary text-white"
        :style="`
            border-radius: 5px;
          `"
      >
        <th>
          <div
            style="
              height: 2rem;
              display: flex;
              align-items: center;
              font-size: 11.5px;
            "
          >
            ITENS
          </div>
        </th>
        <th></th>
        <th>
          <div
            style="
              height: 2rem;
              display: flex;
              align-items: center;
              font-size: 11.5px;
            "
          >
            NECESSIDADES
          </div>
        </th>
        <th>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span style="font-size: 11.5px; margin-right: 10px">SUGERIDOS</span>
            <b-badge variant="dark-primary py-2 px-3" style="font-size: 11.5px">
              ESTIMATIVA
            </b-badge>
          </div>
        </th>
      </tr>

      <tr
        v-for="tableItem in item.diagnostico_produto_item_tabela_itens"
        :key="`table-item-${tableItem.diagnostico_produto_item_tabela_item_id}`"
      >
        <td>{{ tableItem.itens }}</td>
        <td>
          <i
            v-b-tooltip.hover
            :title="tableItem.tooltip"
            class="bx bx-help-circle"
            style="font-size: 1.2rem"
            v-if="tableItem.tooltip"
          />
        </td>
        <td>R$ {{ tableItem.necessidades | decimalWithoutCents }}</td>
        <StepItemTableInputCell :tableItem="tableItem" column="sugeridos" />
      </tr>
    </table>

    <div v-if="item.tipo_tabela === 3">
      <div
        class="bg-secondary"
        :style="`
            width: 100%;
            height: 2.6rem;
            display: flex;
            justify-content: space-between;
            padding: 5px;
            border-radius: 6px;
          `"
      >
        <p class="text-white" style="margin-top: 6px">ITENS</p>
      </div>
      <div style="margin-top: 0.8rem">
        <b-row>
          <b-col
            md="6"
            v-for="tableItem in item.diagnostico_produto_item_tabela_itens"
            :key="`table-item-${tableItem.diagnostico_produto_item_tabela_item_id}`"
          >
            <b-input-group>
              <template #prepend>
                <b-input-group-text class="label-inputs">
                  {{ tableItem.coluna_titulo }}
                </b-input-group-text>
              </template>
              <b-form-input
                readonly
                :value="tableItem.coluna_valor"
                class="inputs"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </div>
    </div>

    <table class="table" v-if="item.tipo_tabela === 4">
      <tr class="bg-secondary text-white">
        <th align="center">
          <div style="height: 2rem; display: flex; align-items: center">
            ITENS
          </div>
        </th>
        <th>
          <div style="height: 2rem; display: flex; align-items: center">
            NECESSIDADES
          </div>
        </th>
      </tr>
      <tr
        v-for="tableItem in item.diagnostico_produto_item_tabela_itens"
        :key="`table-item-${tableItem.diagnostico_produto_item_tabela_item_id}`"
      >
        <td>{{ tableItem.itens }}</td>
        <td>R$ {{ tableItem.necessidades | decimalWithoutCents }}</td>
      </tr>
    </table>

    <table class="table" v-if="[5, 6].includes(item.tipo_tabela)">
      <tr class="bg-secondary text-white">
        <th align="center">
          <div style="height: 2rem; display: flex; align-items: center">
            ITENS
          </div>
        </th>
        <th>
          <div style="height: 2rem; display: flex; align-items: center">
            VALOR ATUAL
          </div>
        </th>
      </tr>
      <tr
        v-for="tableItem in item.diagnostico_produto_item_tabela_itens"
        :key="`table-item-${tableItem.diagnostico_produto_item_tabela_item_id}`"
      >
        <td>{{ tableItem.itens }}</td>
        <td v-if="tableItem.tipo_coluna === 1">
          R$ {{ tableItem.valor_cliente | decimalWithoutCents }}
        </td>
        <td v-else>{{ tableItem.valor_cliente }}</td>
      </tr>
    </table>

    <div v-if="item.tipo_tabela === 7" class="mt-3">
      <div class="font-weight-bold mb-3 h5">
        Despesa com estudos no exterior
      </div>

      <table class="table">
        <tr class="bg-secondary text-white">
          <th class="text-center">Valor mensal</th>
          <th class="text-center">Quantidade de anos</th>
          <th class="text-center">Ano realização</th>
        </tr>

        <tr
          v-for="tableItem in item.diagnostico_produto_item_tabela_itens"
          :key="tableItem.diagnostico_produto_item_tabela_item_id"
        >
          <td>
            <StepItemTableInputCell
              column="intercambio_valor"
              :columnType="1"
              :tableItem="tableItem"
            />
          </td>
          <td>
            <StepItemTableInputCell
              column="intercambio_tempo"
              :columnType="2"
              :tableItem="tableItem"
            />
          </td>
          <td>
            <StepItemTableInputCell
              column="ano_intercambio"
              :columnType="2"
              :tableItem="tableItem"
            />
          </td>
        </tr>
      </table>
    </div>
  </b-col>
</template>

<script>
import StepItemTableInputCell from './StepItemTableInputCell';
import {
  uploadProductItemOption,
  deleteOptionFile
} from '@/services/requests/diagnosis';

import { cloneObject } from '@/helpers';

export default {
  name: 'StepItemTable',
  props: {
    item: Object,
    diagnosis: Object,
    diagnosisProduct: Object
  },
  components: {
    StepItemTableInputCell
  },
  computed: {
    totalSugeridos() {
      if (this.item.tipo_tabela !== 1) return null;

      return this.item.diagnostico_produto_item_tabela_itens.reduce(
        (total, item) => total + item.sugeridos,
        0
      );
    }
  },
  methods: {
    async handleFileUpload(option, optionIndex) {
      if (!option.file) return;

      if (!option.diagnostico_produto_item_opcao_id) {
        const updatedItem = await this.saveQuotationRequest();
        const updatedOption =
          updatedItem.diagnostico_produto_item_opcoes[optionIndex];

        option.diagnostico_produto_item_opcao_id =
          updatedOption.diagnostico_produto_item_opcao_id;
      }

      const formData = new FormData();
      formData.append('file', option.file);

      const optionId = option.diagnostico_produto_item_opcao_id;

      uploadProductItemOption(optionId, option.file)
        .then((res) => {
          const optionIndex = this.item.diagnostico_produto_item_opcoes.findIndex(
            (option) => optionId === option.diagnostico_produto_item_opcao_id
          );

          const updatedOption = {
            ...cloneObject(option),
            ...res.data.result.data
          };

          this.$set(
            this.item.diagnostico_produto_item_opcoes,
            optionIndex,
            updatedOption
          );
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },
    handleDeleteFile() {
      if (!confirm('Deseja realmente excluir este arquivo?'));

      deleteOptionFile(this.currentOption.diagnostico_produto_item_opcao_id)
        .then((res) => {
          this.item.aguardando_resposta = true;
          this.$set(
            this.item.diagnostico_produto_item_opcoes,
            this.currentOptionIndex,
            res.data.result.data
          );
          this.editMode = false;
        })
        .catch((error) => {
          let errorMessage = 'Houve um erro ao salvar as opções';

          if (error.response) {
            if (
              error.response.data.statusCode &&
              error.response.data.statusCode !== 200
            ) {
              errorMessage = error.response.data.statusMessage;
            }

            if (
              error.response.data.message &&
              !error.response.data.message.diagnostico_produto_item_opcoes
            ) {
              errorMessage = 'Todos os valores são obrigatórios';
            }
          }

          alert(errorMessage);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.table-column {
  overflow-x: auto;
}

.label-inputs,
.inputs {
  color: var(--grey);
  font-family: arial;

  border-color: var(--border-grey);
  border-radius: 0px;
}

.inputs {
  font-weight: regular;
  background: var(--white);
}

.label-inputs {
  width: 160px;
  font-weight: bold;
  background: var(--light-grey-2);
}

.icon-pencil {
  font-size: 1rem;
  margin-right: 8px;
}
</style>
